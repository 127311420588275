<template>
  <div class="gws_calendar">
    <div class="gws_calendar_header d-sm-flex align-items-center justify-content-center my-4">
      <div class="gws_calendar_header_year d-flex mx-2 align-items-center justify-content-center mb-2 mb-sm-0">
        <button class="gws_calendar_year_button" @click="prevYear">
          <svg width="28" height="28" class="bi">
            <use xlink:href="/assets/img/bootstrap-icons.svg#arrow-left-circle"></use>
          </svg>
        </button>
        <div class="gws_calendar_year_text text-center">{{ year }}</div>
        <button class="gws_calendar_year_button" @click="nextYear">
          <svg width="28" height="28" class="bi">
            <use xlink:href="/assets/img/bootstrap-icons.svg#arrow-right-circle"></use>
          </svg>
        </button>
      </div>
      <div class="gws_calendar_header_month d-flex mx-2 align-items-center justify-content-center">
        <button class="gws_calendar_month_button me-1" @click="prevMonth">
          <svg width="28" height="28" class="bi">
            <use xlink:href="/assets/img/bootstrap-icons.svg#arrow-left-circle"></use>
          </svg>
        </button>
        <select
          class="form-select gws_calendar_month_text"
          id="gws_calendar_month_select"
          aria-label="Select Month"
          v-model="month"
          @change="setMonth"
        >
          <option v-for="(monthName, monthIndex) in monthNames" :key="monthIndex" :value="monthIndex">
            {{ monthName }}
          </option>
        </select>
        <button class="gws_calendar_month_button ms-1" @click="nextMonth">
          <svg width="28" height="28" class="bi">
            <use xlink:href="/assets/img/bootstrap-icons.svg#arrow-right-circle"></use>
          </svg>
        </button>
      </div>
      <slot name="calendarHeaderAdd"></slot>
    </div>
    <div class="gws_calendar_body mx-3">
      <div class="gws_calendar_title_month d-flex mx-auto">
        <div class="gws_calendar_title_artist">{{ t("general.artist") }}</div>
        <div
          :class="selectedDay === day ? 'gws_calendar_title_day selected' : 'gws_calendar_title_day'"
          v-for="day in getMonthDays"
          :key="'day_' + day"
          :id="'calday-' + day"
        >
          <div class="gws_calendar_day">{{ day }}</div>
          <div class="gws_calendar_weekday">{{ getWeekday(day) }}</div>
        </div>
      </div>
      <slot name="calendarContent" :monthDays="getMonthDays" :month="month" :year="year"></slot>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n/index";
const currentDate = new Date();
export default {
  props: {
    initYear: {
      type: Number,
      default: currentDate.getFullYear(),
    },
    initMonth: {
      type: Number,
      default: currentDate.getMonth(),
    },
    selectedDay: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update"],
  setup(props, ctx) {
    const { t } = useI18n();
    const monthNames = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];
    const weekdays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    const year = ref(props.initYear);
    const month = ref(props.initMonth);
    const getMonthDays = computed(() => new Date(year.value, month.value + 1, 0).getDate());
    function getWeekday(day) {
      const weekday = new Date(year.value, month.value, day);
      return weekdays[weekday.getDay()];
    }
    return {
      t,
      monthNames,
      year,
      month,
      getMonthName(month) {
        return monthNames[month];
      },
      getMonthDays,
      getWeekday,
      prevYear() {
        year.value -= 1;
        ctx.emit("update", { year: year.value, month: month.value });
      },
      nextYear() {
        year.value += 1;
        ctx.emit("update", { year: year.value, month: month.value });
      },
      prevMonth() {
        month.value = month.value < 1 ? 11 : month.value - 1;
        ctx.emit("update", { year: year.value, month: month.value });
      },
      nextMonth() {
        month.value = month.value > 10 ? 0 : month.value + 1;
        ctx.emit("update", { year: year.value, month: month.value });
      },
      setMonth() {
        // month.value = month.value > 10 ? 0 : month.value + 1;
        // console.log(month.value);
        ctx.emit("update", { year: year.value, month: month.value });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: #fa8585a6;
}
</style>
