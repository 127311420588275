<template>
  <div class="roster my-4">
    <Calendar @update="getCurrentJobs" :initYear="year" :initMonth="month" :selectedDay="selectedDay">
      <template v-slot:calendarHeaderAdd>
        <div class="gws_calendar_header_month d-flex mx-2 align-items-center justify-content-center">
          <button
            @click="toggleSelectFilter"
            :title="t('general.filter') + ': ' + t('general.status')"
            type="button"
            class="btn btn-success d-block btn-icon mt-2 mt-md-0"
          >
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#filter-circle"></use>
            </svg>
            {{ t("general.status") }}
          </button>
        </div>
      </template>
      <template #calendarContent="calendarContent">
        <div class="gws_calendar_content_month d-flex mx-auto" v-for="job in jobs" :key="job.id">
          <div class="gws_calendar_content_artist">
            <router-link :to="'/addresses/edit/' + job.artist.id">{{ job.artist.name }}</router-link>
          </div>
          <div
            :class="'gws_calendar_content_day' + getAvailabilityClass(day, job.artist.availabilities)"
            v-for="day in calendarContent.monthDays"
            :key="'day_' + day"
          >
            <template v-for="gig in job.gigs" :key="gig.gig_id">
              <div
                v-if="calDay(gig.gig_date) === day && isStatusRosterDisplay(gig.gig_status)"
                :style="currentStatusColors(gig.gig_status)"
                class="gws_cal_day_active"
                @mouseenter="selectedDay = day"
                @mouseleave="selectedDay = 0"
              >
                <router-link
                  :to="'/addresses/jobs/' + job.artist.id + '/' + gig.job.id"
                  @mouseenter="toggleTooltip($event, gig)"
                  @mouseleave="toggleTooltip"
                ></router-link>
              </div>
            </template>
          </div>
        </div>
      </template>
    </Calendar>
    <!-- <pre>
      {{ jobs }}
      {{ availabilities }}
    </pre> -->
  </div>
  <Tooltip :active="showTooltip" :event="mouseEvent">
    <span v-if="tooltipGig?.gig_status">{{ t("general.status") }}: {{ statusName(tooltipGig.gig_status) }}</span>
    <span v-if="tooltipGig?.organizer">
      <br />
      {{ t("general.organizer") }}: {{ getDisplayName(tooltipGig.organizer) }}
    </span>
    <span v-if="tooltipGig?.venue">
      <br />
      {{ t("general.venue") }}: {{ getDisplayName(tooltipGig.venue) }}
    </span>
    <span v-if="tooltipGig?.venue?.city">
      <br />
      {{ t("general.city") }}: {{ tooltipGig.venue.city
      }}<span v-if="tooltipGig?.venue?.country_id">, {{ getCountryName(tooltipGig.venue.country_id) }}</span>
    </span>
    <span v-if="tooltipGig?.job?.offer_fee">
      <br />
      {{ t("job.fee") }}: {{ tooltipGig.job.offer_fee }} {{ tooltipGig?.job?.currency }}
    </span>
  </Tooltip>
  <Modal @close="afterUpdateStatuses" :modalActive="selectFilterActive">
    <template v-slot:header>{{ t("general.filter") }}: {{ t("general.status") }}</template>
    <template v-slot:body>
      <div v-for="status in statuses" :key="status.id" class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" v-model="status.roster_display" :id="status.id" />
        <label class="form-check-label" :for="'category_' + status.id">{{ status.name }}</label>
      </div>
      <hr />
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          v-model="alwaysShowArtistWithAvailability"
          id="alwaysShowArtistWithAvailability"
        />
        <label class="form-check-label" for="all">{{ t("availability.alwaysShowArtistWithAvailability") }}</label>
      </div>
    </template>
    <template v-slot:footer>
      <button @click="afterUpdateStatuses" type="button" class="btn btn-secondary">{{ t("general.close") }}</button>
    </template>
  </Modal>
</template>

<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useApiQuery } from "@/composables/useApiQueries";
import { useI18n } from "vue-i18n/index";
import Calendar from "@/components/Calendar.vue";
import { getContrastColor } from "@/composables/utilities.js";
import Tooltip from "@/components/Tooltip.vue";
import Modal from "@/components/Modal.vue";
const currentDate = new Date();
export default {
  name: "roster",
  components: {
    Calendar,
    Tooltip,
    Modal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const year = route.params.year.length > 0 ? ref(parseInt(route.params.year, 10)) : ref(currentDate.getFullYear());
    const month = route.params.month.length > 0 ? ref(parseInt(route.params.month, 10)) : ref(currentDate.getMonth());
    const jobsFromDB = ref([]);
    const alwaysShowArtistWithAvailability = ref(true);
    const { result: jobs, query: getJobs } = useApiQuery({
      url: "search/gig/bydate",
      defaultVal: [],
      pfn: function (data) {
        let artist = [];
        if (Array.isArray(data)) {
          data.forEach(function (job) {
            let index = artist.findIndex((e) => e.artist.id === job.artist?.id);
            if (index === -1 && job?.job?.id && job.artist) {
              artist.push({
                artist: job.artist,
                gigs: [
                  {
                    gig_id: job.id,
                    gig_date: job.gig_date,
                    gig_status: job.status,
                    job_status: job.job.status,
                    job: job.job,
                    organizer: job.organizer,
                    venue: job.venue,
                  },
                ],
              });
            } else if (job?.job?.id && job.artist) {
              artist[index].gigs.push({
                gig_id: job.id,
                gig_date: job.gig_date,
                gig_status: job.status,
                job_status: job.job.status,
                job: job.job,
                organizer: job.organizer,
                venue: job.venue,
              });
            }
          });
        }
        jobsFromDB.value = artist;
        return artist;
      },
    });
    const { result: statuses, query: getStatuses } = useApiQuery({
      url: "status",
      defaultVal: [],
      pfn: function (data) {
        return data.map((status) => {
          status.textcolor = getContrastColor(status.color);
          status.roster_display = status.roster_display === 1 ? true : false;
          return status;
        });
      },
    });
    const { result: countries, query: getCountries } = useApiQuery({ url: "country", defaultVal: [] });
    const { result: availabilities, query: getAvailabilities } = useApiQuery({
      url: "search/availability/bydate",
      defaultVal: [],
    });
    async function getAll() {
      await Promise.all([
        getJobs({ params: { params: { year: year.value, month: month.value + 1 } } }),
        getAvailabilities({ params: { params: { year: year.value, month: month.value + 1 } } }),
        getStatuses(),
        getCountries(),
      ]);
      filterJobsBySelectedStates();
    }
    async function getCurrentJobs(data) {
      router.push({ name: "Roster", params: { year: data.year, month: data.month } });
      year.value = data.year;
      month.value = data.month;
      // await getJobs({ params: { params: { year: data.year, month: data.month + 1 } } });
      await Promise.all([
        getJobs({ params: { params: { year: data.year, month: data.month + 1 } } }),
        getAvailabilities({ params: { params: { year: data.year, month: data.month + 1 } } }),
      ]);
      filterJobsBySelectedStates();
      return jobs;
    }
    function getStatusStyles(status) {
      return typeof status !== "undefined"
        ? "background-color:#" + status.color + ";color:" + status.textcolor + ";"
        : "";
    }

    function currentStatusColors(id) {
      const status = statuses.value.find((status) => status.id == id);
      return getStatusStyles(status);
    }
    function filterJobsBySelectedStates() {
      jobs.value = jobsFromDB.value.filter((job) => {
        return job.gigs.some((gig) =>
          statuses.value.find((status) => status.id === gig.gig_status && status.roster_display === true)
        );
      });

      if (alwaysShowArtistWithAvailability.value) {
        // if (jobs.value.length === 0) {
        //   jobs.value = jobsFromDB.value;
        // }

        // show all artists with availabilities
        // look in jobsDB for id and compare with address_id from availabilities
        // if address_id is NOT present add artist to jobs

        if (Array.isArray(availabilities.value)) {
          availabilities.value.forEach((availability) => {
            const index = jobsFromDB.value.findIndex((job) => job.artist.id === availability.address_id);
            if (index === -1) {
              jobs.value.push({
                artist: {
                  ...availability.addresses,
                  availabilities: [
                    {
                      id: availability.id,
                      address_id: availability.address_id,
                      from: availability.from,
                      to: availability.to,
                      type: availability.type,
                      comment: availability.comment,
                      created_by: availability.created_by,
                      updated_by: availability.updated_by,
                      created_at: availability.created_at,
                      updated_at: availability.updated_at,
                    },
                  ],
                },
                gigs: [],
              });
            } else if (index > -1 && jobs.value[index]?.artist?.availabilities) {
              // if availability.id is NOT present in jobsFromDB.value[index].artist.availabilities add it
              const indexAvailability = jobs.value[index].artist.availabilities.findIndex(
                (a) => a.id === availability.id
              );
              if (indexAvailability === -1) {
                jobs.value[index].artist.availabilities.push({
                  id: availability.id,
                  address_id: availability.address_id,
                  from: availability.from,
                  to: availability.to,
                  type: availability.type,
                  comment: availability.comment,
                  created_by: availability.created_by,
                  updated_by: availability.updated_by,
                  created_at: availability.created_at,
                  updated_at: availability.updated_at,
                });
              }
            }
          });
        }
      }
      //  else {
      //   jobs.value = jobsFromDB.value.filter((job) => {
      //     return job.gigs.some((gig) =>
      //       statuses.value.find((status) => status.id === gig.gig_status && status.roster_display === true)
      //     );
      //   });
      // }
    }

    function afterUpdateStatuses() {
      filterJobsBySelectedStates();
      selectFilterActive.value = false;
    }

    function isStatusRosterDisplay(status) {
      return statuses.value.find((s) => s.id === status && s.roster_display === true);
    }

    function getDisplayName(address) {
      if (!address) {
        return "";
      } else if (address.name) {
        return address.name;
      } else if (address.forename && address.surname) {
        return address.forename + " " + address.surname;
      } else if (address.forename) {
        return address.forename;
      } else if (address.surname) {
        return address.surname;
      } else {
        return "";
      }
    }

    function getCountryName(countryId) {
      const country = countries.value.find((country) => country.id == countryId);
      return country ? country.name : "";
    }

    const showTooltip = ref(false);
    const mouseEvent = ref({});
    const tooltipGig = ref({});

    function toggleTooltip(event, gig) {
      if (gig) {
        tooltipGig.value = gig;
      }
      showTooltip.value = !showTooltip.value;
      mouseEvent.value = event;
    }

    const selectedDay = ref(0);

    function getAvailabilityClass(day, availabilities) {
      let availabilityClasses = "";
      const toTimestamp = (strDate) => {
        const dt = Date.parse(strDate);
        return dt / 1000;
      };
      if (availabilities && availabilities.length > 0) {
        for (let availability of availabilities) {
          if (availability.from && availability.to) {
            const start = toTimestamp(availability.from);
            const end = toTimestamp(availability.to);
            const gigDate = toTimestamp(year.value + "-" + (month.value + 1) + "-" + day);
            if (gigDate >= start && gigDate <= end) {
              if (availability.type === "available") {
                availabilityClasses += " gws_pad_available";
              } else if (availability.type === "unavailable") {
                availabilityClasses += " gws_pad_unavailable";
              } else if (availability.type === "tourperiod") {
                availabilityClasses += " gws_pad_tourperiod";
              }
              break;
            }
          }
        }
      }
      return availabilityClasses;
    }

    const selectFilterActive = ref(false);
    function toggleSelectFilter() {
      selectFilterActive.value = !selectFilterActive.value;
    }

    getAll();
    return {
      t,
      year,
      month,
      jobs,
      statuses,
      getCurrentJobs,
      getDisplayName,
      calDay(dateStr) {
        return new Date(dateStr).getDate();
      },
      currentStatusColors,
      statusName: function (id) {
        const status = statuses.value.find((status) => status.id == id);
        return status ? status.name : "";
      },
      getCountryName,
      showTooltip,
      mouseEvent,
      tooltipGig,
      toggleTooltip,
      selectedDay,
      getAvailabilityClass,
      selectFilterActive,
      toggleSelectFilter,
      afterUpdateStatuses,
      isStatusRosterDisplay,
      availabilities,
      alwaysShowArtistWithAvailability,
    };
  },
};
</script>
