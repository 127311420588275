<template>
  <div class="gws-tooltip" v-if="props.active" :style="position">
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  event: {
    type: Object,
    required: true,
  },
});
const position = computed(() => {
  let top = "0px";
  let left = "0px";
  let transformX = "translateX(0)";
  let transformY = "translateY(0)";
  let borderRadius = "border-";
  let rect = props.event.target.getBoundingClientRect();
  if (rect.top > window.innerHeight / 2) {
    top = `${rect.top + window.scrollY}px`;
    transformY = "translateY(-100%)";
    borderRadius += "bottom-";
  } else {
    top = `${rect.top + window.scrollY + rect.height}px`;
    transformY = "translateY(0)";
    borderRadius += "top-";
  }

  if (rect.left > window.innerWidth / 2) {
    left = `${rect.left + window.scrollX}px`;
    transformX = "translateX(-100%)";
    borderRadius += "right-";
  } else {
    left = `${rect.left + window.scrollX + rect.width}px`;
    transformX = "translateX(0)";
    borderRadius += "left-";
  }
  borderRadius += "radius";
  return {
    top: top,
    left: left,
    transform: transformX + " " + transformY,
    [borderRadius]: "0",
  };
});
</script>

<style lang="scss" scoped>
.gws-tooltip {
  position: absolute;
  z-index: 100;
  margin: 0;
  padding: 0.5rem 0.75rem;
  background: #fffffff0;
  box-shadow: 0 0 8px 0px #00000063;
  border: 2px solid #808080;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
  color: #000;
  p {
    margin: 0;
  }
}
</style>
